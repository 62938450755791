<template>
  <div>
    <hr v-if="!spinner">
    <b-spinner
        v-if="spinner"
        variant="primary"
        class="spinners"
    />
    <div v-if="!spinner">
      <b-row class="mt-1">
        <b-col cols="12">
          <b-card-actions
              class="cari-liste-card"
          >
            <b-row class="mt-50 mb-3">
              <div class="card-header-row-btn" :style="{backgroundColor: $store.getters.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white d-inline-block">Oda Kaydı</h4>
                  <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="bgInfo"
                      class="myButton mr-1 text-white border-0 float-right"
                      to="/odalar"
                      :style="{backgroundColor:$store.getters.cardTitleButton,color:'white'}"
                  >
                    <feather-icon
                        icon="ChevronLeftIcon"
                        class="mr-50"
                    />
                    Oda kayıtları
                  </b-button>
                  <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="bgInfo"
                      @click="odaSave"
                      class="border-0 float-right mr-1"
                      :style="{backgroundColor:$store.getters.cardTitleButton,color:'white'}"
                  >
                    Kaydet
                  </b-button>
                </b-col>
              </div>
            </b-row>
            <b-row class="mb-2">
              <b-col cols="12">
                <b-progress
                    v-model="value"
                    max="100"
                />
              </b-col>
            </b-row>
            <div class="line mt-1"/>
            <b-row>
              <b-col lg="6" md="12" sm="12" cols="12">
                <b-row class="justify-content-start">
                  <b-col lg="6" md="6" sm="6" cols="12">
                    <b-form-group
                        label="Oda Adı"
                        label-for="basicInput"
                        class="mt-1"
                    >
                      <b-form-input
                          id="basicInput"
                          v-model="odaAdi"
                          autocomplete="off"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col lg="6" md="6" sm="6" cols="12">
                    <b-form-group
                        label="Oda Tipi"
                        label-for="basicInput"
                        class="mt-1"
                    >
                      <v-select
                          v-model="odaTipi"
                          :components="{ OpenIndicator, Deselect }"
                          label="isim"
                          :options="odaTipiOption"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col lg="4" md="6" sm="6" cols="12">
                    <b-form-group
                        label="Paylaşımlı Rezervasyon"
                        label-for="basicInput"
                        class="mt-1"
                    >
                      <v-select
                          v-model="paylasimliKontrol"
                          :components="{ OpenIndicator, Deselect }"
                          label="isim"
                          :options="paylasimliOption"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col lg="4" md="6" sm="6" cols="12">
                    <b-form-group
                        label="Yatak Kapasitesi"
                        label-for="basicInput"
                        class="mt-1"
                    >
                      <b-form-input
                          id="basicInput"
                          v-model="yatak"
                          autocomplete="off"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col lg="4" md="6" sm="6" cols="12">
                    <b-form-group
                        label="Kapak Resmi"
                        label-for="basicInput"
                        class="mt-1"
                    >
                      <b-form-file
                          type="file"
                          placeholder="Dosya Seçiniz"
                          drop-placeholder="Drop file here..."
                          accept=".jpg, .png, .gif, .jpeg"
                          :file-name-formatter="formatNames"
                          @change="onFileSelected"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                        label="Oda Özellikleri"
                        label-for="basicInput"
                        class="mt-1"
                    >
                      <div class="demo-inline-spacing">
                        <b-form-checkbox
                            v-model="otopark"
                            value="1"
                            class="custom-control-success"
                        >
                          Ücretsiz Otopark
                        </b-form-checkbox>
                        <b-form-checkbox
                            v-model="dus"
                            value="1"
                            class="custom-control-info"
                        >
                          24 Saat Sıcak Duş
                        </b-form-checkbox>
                        <b-form-checkbox
                            v-model="miniBar"
                            value="1"
                            class="custom-control-primary"
                        >
                          Mini Bar
                        </b-form-checkbox>
                        <b-form-checkbox
                            v-model="kabloTV"
                            value="1"
                            class="custom-control-danger"
                        >
                          Kablo Tv
                        </b-form-checkbox>
                        <b-form-checkbox
                            v-model="wifi"
                            value="1"
                            class="custom-control-warning"
                        >
                          Ücretsiz Wifi
                        </b-form-checkbox>
                        <b-form-checkbox
                            v-model="kahvalti"
                            value="1"
                            class="custom-control-info"
                        >
                          Sabah Kahvaltısı
                        </b-form-checkbox>
                        <b-form-checkbox
                            v-model="housekeeping"
                            value="1"
                            class="custom-control-success"
                        >
                          House Keeping
                        </b-form-checkbox>
                      </div>
                    </b-form-group>
                  </b-col>
                  <div class="line mb-2"/>
                  <b-col
                      v-for="tarife in tarifeListesi"
                      :key="tarife.tarifeID"
                      lg="4"
                      md="6"
                      sm="12"
                      cols="12"
                  >
                    <b-form-group
                        :label="tarife.tarifeAdi + ' Tarifesi'"
                        label-for="basicInput"
                        class="mt-1"
                    >
                      <b-form-input
                          id="basicInput"
                          v-model="tarifeler[tarife.tarifeID]"
                          :name="tarife.tarifeID + '-ucret'"
                          step="0.0001"
                          autocomplete="off"
                          type="number"
                          v-numericOnly
                      />
                    </b-form-group>
                  </b-col>
                  <div class="line"/>
                </b-row>
              </b-col>
              <b-col lg="6" md="12" sm="12" cols="12">
                <b-form-group
                    label="Oda İçerik Bilgileri"
                    label-for="basicInput"
                    class="mt-1"
                >
                  <quill-editor
                      v-model="content"
                      :options="snowOption"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" class="mt-5">
                <h5 class="mt-4">
                  Tarife Ve Fiyatlandırma
                </h5>
                <b-row class="justify-content-start">
                  <b-col lg="3" md="6" sm="6" cols="6">
                    <b-form-group
                        label="Oda Kapatma"
                        label-for="basicInput"
                        class="mt-1"
                    >
                      <v-select
                          v-model="odaKapatma"
                          :components="{ OpenIndicator, Deselect }"
                          label="isim"
                          :options="odaKapatmaOption"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Oda Kapatma -->
                  <b-col
                      v-for="tarife in tarifeListesi"
                      :key="tarife.tarifeID + 5"
                      lg="3" md="6" sm="6" cols="6"
                  >
                    <b-form-group
                        :label="tarife.tarifeAdi + ' Oda Kapatma Fiyatı'"
                        label-for="basicInput"
                        class="mt-1"
                    >
                      <b-form-input
                          id="basicInput"
                          v-model="odaKapatmaFiyat[tarife.tarifeID]"
                          :name="tarife.tarifeID + '-odaKapatma'"
                          step="0.0001"
                          type="number"
                          v-numericOnly
                      />
                    </b-form-group>
                  </b-col>
                  <b-col lg="4" md="6" sm="6" cols="12">
                    <b-form-group
                        label="Zorunlu Oda Kapatma"
                        label-for="basicInput"
                        class="mt-2"
                    >
                      <b-form-checkbox
                          checked="true"
                          class="custom-control-primary"
                          name="check-button"
                          switch
                          v-model="zorunluKapatma"
                      >
                        <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon"/>
                      </span>
                        <span class="switch-icon-right">
                        <feather-icon icon="XIcon"/>
                      </span>
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <p class="text-success">
                      * Oda Kapatmada belirtilen fiyatlar, diğer yatakların
                      yatak başı ücretidir. Toplam tutarı girmeyiniz!.
                    </p>
                  </b-col>
                  <div class="line bg-warning opacity"/>
                  <!-- Evli Çift -->
                  <b-col lg="3" md="6" sm="6" cols="6">
                    <b-form-group
                        label="Evli Çift Rezervasyonu"
                        label-for="basicInput"
                        class="mt-1"
                    >
                      <v-select
                          v-model="ciftTarifeKontrol"
                          :components="{ OpenIndicator, Deselect }"
                          label="isim"
                          :options="ciftTarifeOption"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                      v-for="tarife in tarifeListesi"
                      :key="tarife.tarifeID + 10"
                      lg="3" md="6" sm="6" cols="6"
                  >
                    <b-form-group
                        :label="tarife.tarifeAdi + ' Evli Çift Toplam Tutar'"
                        label-for="basicInput"
                        class="mt-1"
                    >
                      <b-form-input
                          id="basicInput"
                          v-model="ciftTarife[tarife.tarifeID]"
                          :name="tarife.tarifeID + '-ciftTarife'"
                          step="0.0001"
                          autocomplete="off"
                          type="number"
                          v-numericOnly
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <p class="text-success">
                      Odada evli çiftler için duble yatak mevcutsa konukların *
                      toplam konaklama ücretini giriniz. Eğer odada duble yatak
                      bulunmuyorsa Evli Çift Rezervasyonu seçeneğini "Hayır"
                      olarak seçiniz.
                    </p>
                  </b-col>
                  <!-- Diğer Yatak -->
                  <div class="line bg-warning opacity"/>
                  <b-col lg="6" md="6" sm="12" cols="12">
                    <b-form-group
                        label="Diğer Yataklar İçin Farklı Ücret Ödeme"
                        label-for="basicInput"
                        class="mt-1"
                    >
                      <v-select
                          v-model="digerYatakKontrol"
                          :components="{ OpenIndicator, Deselect }"
                          label="isim"
                          :options="digerYatakOption"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                      v-for="tarife in tarifeListesi"
                      :key="tarife.tarifeID + 15"
                      lg="3" md="6" sm="6" cols="6"
                  >
                    <b-form-group
                        :label="tarife.tarifeAdi + ' Diğer Yatak Farklı Ücret'"
                        label-for="basicInput"
                        class="mt-1"
                    >
                      <b-form-input
                          id="basicInput"
                          v-model="digerYatak[tarife.tarifeID]"
                          :name="tarife.tarifeID + '-digerYatak'"
                          step="0.0001"
                          type="number"
                          v-numericOnly
                          autocomplete="off"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <p class="text-success">
                      *Odaya eklenen diğer yetişkinler için farklı bir
                      ücretlendirme kullanmak istiyorsanız lütfen yukarıdaki
                      alanları doldurunuz. Standart tarifeler için "Standart
                      Tarife Kullan" seçeneğini seçiniz.
                    </p>
                  </b-col>
                  <!-- Ek Yatak-->
                  <div class="line bg-warning opacity"/>
                  <b-col lg="3" md="6" sm="6" cols="6">
                    <b-form-group
                        label="Ek Yatak"
                        label-for="basicInput"
                        class="mt-1"
                    >
                      <v-select
                          v-model="ekYatakKontrol"
                          :components="{ OpenIndicator, Deselect }"
                          label="isim"
                          :options="ekYatakOption"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                      v-for="tarife in tarifeListesi"
                      :key="tarife.tarifeID + 20"
                      lg="3" md="6" sm="6" cols="6"
                  >
                    <b-form-group
                        :label="tarife.tarifeAdi + ' Ek Yatak'"
                        label-for="basicInput"
                        class="mt-1"
                    >
                      <b-form-input
                          id="basicInput"
                          v-model="ekYatak[tarife.tarifeID]"
                          :name="tarife.tarifeID + '-ekYatak'"
                          step="0.0001"
                          type="number"
                          v-numericOnly
                          autocomplete="off"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col lg="3" md="6" sm="6" cols="6">
                    <p class="text-success">
                      *Ek yatak, özel durumlarda ve ek yatak imkanı olan odalar
                      için aktif olmalıdır.
                    </p>
                  </b-col>
                  <!-- Ücretli Çocuk -->
                  <div class="line bg-warning opacity"/>
                  <b-col lg="3" md="6" sm="6" cols="6">
                    <b-form-group
                        label="Ücretli Çocuk Tarifesi"
                        label-for="basicInput"
                        class="mt-1"
                    >
                      <v-select
                          v-model="ucretliCocukKontrol"
                          :components="{ OpenIndicator, Deselect }"
                          label="isim"
                          :options="ucretliCocukOption"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                      v-for="tarife in tarifeListesi"
                      :key="tarife.tarifeID + 25"
                      lg="3" md="6" sm="6" cols="6"
                      class="text-left"
                  >
                    <b-form-group
                        :label="tarife.tarifeAdi + ' Ücretli Çocuk Fiyatı'"
                        label-for="basicInput"
                        class="mt-1"
                    >
                      <b-form-input
                          id="basicInput"
                          v-model="ucretliCocuk[tarife.tarifeID]"
                          :name="tarife.tarifeID + '-ucretliCocuk'"
                          step="0.0001"
                          type="number"
                          v-numericOnly
                          autocomplete="off"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col lg="3" md="6" sm="6" cols="6">
                    <p class="text-success">
                      *Standart olarak yetişkin konuğun tarifesinin %50
                      tutarının hesaplanması için Ücretli Çocuk Tarifesi
                      seçeneğini %50 indirimli olarak seçiniz. Farklı bir tarife
                      kullanılacak ise "Özel Fiyat Uygula" seçimini yapıp ilgili
                      alanları doldurunuz.
                    </p>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card-actions>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import store from '@/store'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  BProgress,
  BSpinner,
  BRow,
  BCol,
  BCard,
  BInputGroupAppend,
  BInputGroupPrepend,
  BFormCheckbox,
  BFormCheckboxGroup,
  BTable,
  BModal,
  BImg,
  BInputGroup,
  BContainer,
  BFormInput,
  BPagination,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BFormFile,
} from 'bootstrap-vue'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    BCardActions,
    quillEditor,
    BSpinner,
    BProgress,
    BButton,
    BContainer,
    BAvatar,
    BCard,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BImg,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormCheckbox,
    BFormCheckboxGroup,
    BTable,
    BModal,
    BInputGroup,
    quillEditor,
    BFormFile,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      meSayac: 0,
      spinner: true,
      Deselect: {
        render: createElement => createElement('span', ''),
      },
      OpenIndicator: {
        render: createElement => createElement('span', ''),
      },
      snowOption: {
        placeholder: '',
      },
      value: 0,
      ucret: null,
      odaAdi: '',
      zorunluKapatma: '',
      content: '',
      odaTipi: { isim: 'Oda Tipini Seçiniz' },
      odaTipiOption: [],
      yatak: '',
      selectedFile: [],
      otopark: 0,
      dus: 0,
      miniBar: 0,
      kabloTV: 0,
      wifi: 0,
      kahvalti: 0,
      housekeeping: 0,
      tarifeListesi: [],
      odaTarifeDetay: [],
      tarifeler: [],
      odaKapatmaFiyat: [],
      ciftTarife: [],
      digerYatak: [],
      ekYatak: [],
      ucretliCocuk: [],
      paylasimliKontrol: {
        isim: 'Kapalı',
        key: 0
      },
      paylasimliOption: [
        {
          isim: 'Kapalı',
          key: 0
        },
        {
          isim: 'Açık',
          key: 1
        },
      ],
      odaKapatma: {
        isim: 'Kapalı',
        key: 0
      },
      odaKapatmaOption: [
        {
          isim: 'Kapalı',
          key: 0
        },
        {
          isim: 'Açık',
          key: 1
        },
      ],
      ciftTarifeKontrol: {
        isim: 'Kapalı',
        key: 0
      },
      ciftTarifeOption: [
        {
          isim: 'Kapalı',
          key: 0
        },
        {
          isim: 'Açık',
          key: 1
        },
      ],
      digerYatakKontrol: {
        isim: 'Standart Tarife Kullan',
        key: 0
      },
      digerYatakOption: [
        {
          isim: 'Standart Tarife Kullan',
          key: 0
        },
        {
          isim: 'Açık',
          key: 1
        },
      ],
      ekYatakKontrol: {
        isim: 'Mevcut Değil',
        key: 0
      },
      ekYatakOption: [
        {
          isim: 'Mevcut Değil',
          key: 0
        },
        {
          isim: 'Açık',
          key: 1
        },
      ],
      ucretliCocukKontrol: {
        isim: 'Özel Fiyat Uygula',
        key: 1
      },
      ucretliCocukOption: [
        {
          isim: 'Özel Fiyat Uygula',
          key: 1
        },
        {
          isim: '50% İndirimli',
          key: 0
        },
        {
          isim: 'Devre Dışı',
          key: 3
        },
      ],
    }
  },
  created() {
    this.$store
        .dispatch('emptyOda')
        .then(res => {
          this.spinner = false
          this.tarifeListesi = res.tarifeListesi
          this.odaTipiOption = res.odaTipListesi
          const mesaj = this.$store.getters.notificationSettings(res)
          if (mesaj) {
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position },
            )
          }
        })
        .catch((err, position = localStorage.getItem('otelSuccessReturn')) => {
          this.spinner = false
          const mesaj = this.$store.getters.notificationSettings(err)
          if (mesaj) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          }
        })
  },
  beforeDestroy() {
    this.$store.commit('clearEmptyOda')
  },
  methods: {
    odaSave(e) {
      if (this.odaTipi.tip) {
        const fd = new FormData()
        fd.append('resim', this.selectedFile[0] || '')
        fd.append('crm_token', localStorage.getItem('otelUserToken'))
        fd.append('serviceName', 'web')
        fd.append('methodName', 'odaDuzenle')
        fd.append('odaAdi', this.odaAdi)
        fd.append('zorunluKapatma', this.zorunluKapatma ? 1 : 0)
        fd.append('odaKayit', true)
        fd.append('fotoKaydi', true)
        fd.append('tip', this.odaTipi.tip)
        fd.append('paylasimli', this.paylasimliKontrol.key)
        fd.append('yatak', this.yatak)
        fd.append('dus', typeof this.dus !== 'boolean' ? this.dus : this.dus ? 1 : 0)
        fd.append('miniBar', typeof this.miniBar !== 'boolean' ? this.miniBar : this.miniBar ? 1 : 0)
        fd.append('kabloTV', typeof this.kabloTV !== 'boolean' ? this.kabloTV : this.kabloTV ? 1 : 0)
        fd.append('wifi', typeof this.wifi !== 'boolean' ? this.wifi : this.wifi ? 1 : 0)
        fd.append('kahvalti', typeof this.kahvalti !== 'boolean' ? this.kahvalti : this.kahvalti ? 1 : 0)
        fd.append('housekeeping', typeof this.housekeeping !== 'boolean' ? this.housekeeping : this.housekeeping ? 1 : 0)
        fd.append('icerik', this.content)
        fd.append('odaKapatma', this.odaKapatma.key)
        fd.append('ciftTarife', this.ciftTarifeKontrol.key)
        fd.append('digerYatak', this.digerYatakKontrol.key)
        fd.append('ekYatak', this.ekYatakKontrol.key)
        fd.append('ucretliCocuk', this.ucretliCocukKontrol.key)
        Object.entries(this.tarifeler)
            .forEach(entry => {
              const [key, value] = entry
              fd.append([`${key}-ucret`], value || 0)
            })
        Object.entries(this.odaKapatmaFiyat)
            .forEach(entry => {
              const [key, value] = entry
              fd.append([`${key}-odaKapatma`], value || 0)
            })
        Object.entries(this.ciftTarife)
            .forEach(entry => {
              const [key, value] = entry
              fd.append([`${key}-ciftTarife`], value || 0)
            })
        Object.entries(this.digerYatak)
            .forEach(entry => {
              const [key, value] = entry
              fd.append([`${key}-digerYatak`], value || 0)
            })
        Object.entries(this.ekYatak)
            .forEach(entry => {
              const [key, value] = entry
              fd.append([`${key}-ekYatak`], value || 0)
            })
        Object.entries(this.ucretliCocuk)
            .forEach(entry => {
              const [key, value] = entry
              fd.append([`${key}-ucretliCocuk`], value || 0)
            })
        axios
            .post(store.state.POST_URL, fd, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              onUploadProgress: function (progressEvent) {
                this.value = parseInt(
                    Math.round((progressEvent.loaded / progressEvent.total) * 95),
                )
              }.bind(this),
            })
            .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
              if (res.data.result.status == 200) {
                this.value = 0
                this.$router.push('/odalar')
                const mesaj = this.$store.getters.notificationSettings(res.data)
                if (mesaj) {
                  this.$toast(
                      {
                        component: ToastificationContent,
                        props: {
                          title: 'Başarılı',
                          text: mesaj,
                          icon: 'ThumbsUpIcon',
                          variant: 'success',
                        },
                      },
                      { position },
                  )
                }
              } else {
                const mesaj = this.$store.getters.notificationSettings(res.data)
                this.value = 0
                if (mesaj) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Hata!',
                      text: mesaj,
                      icon: 'ThumbsUpIcon',
                      variant: 'danger',
                    },
                  })
                }
              }
            })
            .catch(err => {
              this.value = 0
              const mesaj = this.$store.getters.notificationSettings(err.data)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'danger',
                  },
                })
              }
            })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Hata!',
            text: 'Oda Tipini Seçiniz',
            icon: 'ThumbsUpIcon',
            variant: 'danger',
          },
        })
      }
    },
    onFileSelected(event) {
      this.selectedFile.push(event.target.files[0])
    },
    formatNames(files) {
      return files.length === 1
          ? files[0].name
          : `${files.length} resim seçildi`
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style>
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: "Dosya" !important;
}
</style>
<style scoped>
.progress {
  height: 0.557rem !important;
}

.my-down-button {
  margin: 0 auto;
  display: flex;
}

.opacity {
  opacity: 0.4 !important;
}

.row[data-v-61f55772] {
  justify-content: start;
}

.quill-editor {
  height: 305px;
}

.line {
  height: 2px;
  background-color: #ebe9f1;
  width: 100%;
}

.my-top-row {
  justify-content: space-between;
  display: flex;
}

@keyframes mymove {
  0% {
    background-color: pink;
  }

  25% {
    background-color: pink;
  }

  50% {
    background-color: #00ffd1;
  }

  75% {
    background-color: #00ffd1;
  }

  100% {
    background-color: #00ffd1;
    width: 480px;
  }
}

.editOnPrgoress {
  width: 0px;
  height: 4px;
  background: #38e54d;
  position: relative;
  animation: mymove 4000ms infinite;
  border-radius: 20px;
  transform: translateY(20px);
}

.odaKayitRow {
  justify-content: space-around !important;
}

.table td[data-v-199ebc45][data-v-199ebc45] {
  text-align: center;
}

.myColor {
  width: 30px;
  height: 30px;
  border-radius: 14px;
  margin: 0 auto !important;
}

[dir] .table th[data-v-199ebc45],
[dir] .table td[data-v-199ebc45] {
  padding: 0.1rem;
}

.row {
  justify-content: end;
}

[dir] .table th,
[dir] .table td {
  padding: 0.72rem;
  border-top: 1px solid #ebe9f1;
  max-width: 450px !important;
}

.card-header {
  background-color: #2e83c9;
  height: 30px;
  position: relative;
}

.card-header h4 {
  position: absolute;
  color: white;
  margin-top: 10px;
}

[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  background-color: #31526f;
  color: white;
  padding: 0.6rem !important;
  border: none;
  text-align: center;
}

[dir] .table th[data-v-4ab16a2a],
[dir] .table td {
  padding: 0.2rem !important;
  border-top: 1px solid #ebe9f1;
  max-width: 450px !important;
}

.card-body {
  padding: 10px 15px;
}
</style>
